@import '../../../assets/scss/theme.scss';

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.MuiFormHelperText-root {
  color: $red !important;
}
