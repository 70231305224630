// @import '../../assets/scss/theme.scss';

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

.image-wrapper {
  width: 96px;
  height: 96px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.image {
  left: 0;
  top: 0;
  height: 96px;
  width: 96px;
  position: absolute;
  border-radius: 50%;
}
.image-overlay {
  width: 96px;
  height: 96px;
  opacity: 0.6;
  position: absolute;
  background-color: #000000;
  border-radius: 50%;
}

.icon-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #fff;
  }
}
